<template>
    <div id="app">
        <headers />
        <router-view></router-view>
        <footers  />
        <!-- <service /> -->
    </div>
</template>
<script>
import headers from '@/components/headers';
import footers from "@/components/footer";
// import service from "@/components/service";
export default {
    name: 'App',
    components: {
        headers,
        footers,
        // service
    },
    data() {
        return {
            isLoaded: null,
        };
    },
    methods: {
    },
    created() {
        // 使用全局导航守卫
        // this.$router.afterEach((to, from) => {
        //     this.isLoaded = true; // 路由切换时重置为 false
        // });
    },
}
</script>
<style lang="scss">
@import "../public/font-css/main.css";
</style>