<!--  -->
<template>
    <div class="footr">
        <div class="content">
            <div class="lst">
                <img style="width: 187px;height: 61px;margin: 0 0 18px 0;" src="@/assets/images/logo2.png" alt="">
                <p class="dan" style="font-size:14px;color:#ffffff;margin:0 0 0 0;">
                    联系电话
                </p>
                <p class="shuang" style="color:#ffffff;margin:0 0 10px 0;">
                    17695634632
                </p>
                <p class="dan" style="margin:0 0 0 0;">
                    邮箱
                </p>
                <p class="shuang" style="color:#ffffff;">
                    tianqingshuan2023@163.com
                </p>
            </div>
            <div class="lst">
                <p class="dan">
                    解决方案
                </p>
                <p class="shuang">
                    数据资产入表解决方案
                </p>
                 <p class="shuang">
                    数据资产管理解决方案
                </p>
                <p class="shuang">
                    行业大模型解决方案
                </p>
                <p class="shuang">
                    水上安全智能解决方案
                </p>
                <p class="shuang">
                    智能地理绘图解决方案
                </p>
            </div>
            <div class="lst">
                <p class="dan">
                    产品系列
                </p>
                <p class="shuang">
                    游泳溺水监测系统
                </p>
                
            </div>
            <div class="lst">
                <p class="dan">
                    关于我们
                </p>
                <p class="shuang">
                    加入我们
                </p>
            </div>
            <div class="lst lst3">
                <div class="dan" style="margin-left: 40px;">
                    联系我们
                </div>
                <div class="lst2_box">
                    <div class="lst2">
                        <div class="di">
                            <img src="@/assets/images/wxchart.png" width="101" height="101">
                        </div>
                        <p class="dan" style="font-size:12px;opacity: 0.9;text-align: center;margin: 5px 0 0 0;">
                            添加企业微信
                        </p>
                    </div>
                    <div class="lst2">
                        <div class="di">
                            <img src="@/assets/images/wxchart.png" width="101" height="101">
                        </div>
                        <p class="dan" style="font-size:12px;opacity: 0.9;text-align: center;margin: 5px 0 0 0;">
                            添加微信公众号
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="codes">
            <div class="text">备案号： 津ICP备2024022784号-2 </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            scrollTop: 0,
            img: false
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        showWeChatImg() {
            let WeChatImg = this.$refs.WeChatImg
            WeChatImg.classList.add('activemouseenter');
            WeChatImg.classList.remove('activemouseleave');
            this.img = true

        },
        hideWeChatImg() {
            let WeChatImg = this.$refs.WeChatImg
            // WeChatImg.classList.add('activemouseleave');
            // WeChatImg.classList.remove('activemouseenter');
            this.img = false
        }
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() {

    }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.footr {
    width: 100%;

    box-sizing: border-box;
    padding: 50px 0px 60px;
    background-color: #333638 !important;
    border-color: #21252c !important;
}
.footr .content {
    width: 1200px;
    height: 100%;
    margin: 0px auto;
    display: flex;
    flex-wrap: wrap;
}
.footr .content .lst {
    margin-right: 88px;
}
.footr .content .lst:nth-child(1) {
    margin-top: -30px;
}
// .footr .content .lst:nth-child(1) > img {
//     margin: 74px 0 0 0;
// }
.footr .content .lst:nth-child(2) {
    // width: 84px;
}
.footr .content .lst:nth-child(2) p {
    // font-size: 14px;
}
.footr .content .lst .dan {
    font-size: 18px;
    color: #fff;
    margin: 0 0 10px 0;
}
.footr .content .lst .shuang {
    font-size: 14px;
    color: #888;
    line-height: 35px;
}
.footr .content .lst:nth-child(3) {
    // width: 70px;
}
.footr .content .lst .lst2 {
    // width: 84px;
    // float: right;
    margin-left: 20px;
}
.lst2_box {
    display: flex;
    margin: 0 0 0 20px;
}
.footr .content .lst3 {
    width: 268px;
    margin-right: 0px;
    border-left: 1px solid #bbb;
    // display: flex;
}
.footr .content .lst3 .di {
    margin-top: 23px;
}
.codes {
    display: flex;
    justify-content: center;
    margin: 50px 0 0 0;
    .text {
        width: 1200px;
        height: 40px;
        font-weight: 400;
        font-size: 14px;
        color: #888;
        text-align: center;
    }
}
</style>
